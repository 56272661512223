import React from "react";
import "./Splash.scss";
import { Link } from "react-router-dom";

import Appbar from "../../components/Appbar/Appbar";
import Footer from "../../components/Footer/Footer";

import FlipImgHeader from "../../assets/flip_header_img.jpg";
import { Box, Button, Typography } from "@mui/material";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

import Gallery1 from '../../assets/gallery-images/gallery_1.jpg';
import Gallery2 from '../../assets/gallery-images/gallery_2.jpg';
import Gallery3 from '../../assets/gallery-images/gallery_3.jpg';
import Gallery4 from '../../assets/gallery-images/gallery_4.jpg';
import Gallery5 from '../../assets/gallery-images/gallery_5.jpg';
import Gallery6 from '../../assets/gallery-images/gallery_6.jpg';
import Gallery7 from '../../assets/gallery-images/gallery_7.jpg';
import Gallery8 from '../../assets/gallery-images/gallery_8.jpg';
import Gallery9 from '../../assets/gallery-images/gallery_9.jpg';

import Commercial from '../../assets/2024_carp_homes.mp4';

import ReactPlayer from 'react-player';

const itemData = [
  {
    img: Gallery2,
    title: 'Gallery Image',
  },
  {
    img: Gallery1,
    title: 'Gallery Image',
  },
  {
    img: Gallery3,
    title: 'Gallery Image',
  },
  {
    img: Gallery4,
    title: 'Gallery Image',
  },
  {
    img: Gallery8,
    title: 'Gallery Image',
  },
  {
    img: Gallery5,
    title: 'Gallery Image',
  },
  {
    img: Gallery9,
    title: 'Gallery Image',
  },
  {
    img: Gallery6,
    title: 'Gallery Image',
  },
  {
    img: Gallery7,
    title: 'Gallery Image',
  },
];

const Splash = (props) => {
  return (
    <div>
      <Appbar
        colorMode={props.colorMode}
        theme={props.theme}
        mode={props.mode}
      />

      <section
        className="landing"
        style={{
          backgroundColor:
            props.theme.palette.mode === "dark"
              ? props.theme.palette.primary.dark
              : props.theme.palette.secondary.main,
        }}
      >
        <div
          className="flex-container main-img-container"
          style={{
            backgroundColor:
              props.theme.palette.mode === "dark"
                ? props.theme.palette.secondary.main
                : props.theme.palette.primary.main,
          }}
        >
          <img src={FlipImgHeader} alt="Beautiful kitchen space of flipped home" />
        </div>
        <div className="flex-container main-text-container">
          <div>
            <h2
              className="heading-title l1"
              style={{
                color:
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.main,
              }}
            >
              Your
            </h2>
            <h2 className="heading-title l2">Fixer Upper</h2>
            <h2
              className="heading-title l3"
              style={{
                color:
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.main,
              }}
            >
              Listing Agent
            </h2>
          </div>
        </div>
      </section>

      {/* <hr className='divider' /> */}
      <div className="content-wrapper">
        <section className="welcome-container">
          <Box sx={{ width: "75%", margin: "0 auto" }}>
            <Typography className="welcome-h1"
              sx={{
                fontFamily: "karla",
                color: props.theme.palette.warning.main,
              }}
            >
              Welcome to <span>Carp</span>Homes,
            </Typography>
            <br />
            <Typography
              sx={{
                fontSize: "1.25rem",
                lineHeight: "2.25rem",
                color:
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.main,
              }}
            >
              where selling your fixer-upper home is a breeze. Say goodbye to
              overwhelming repair lists and stressful renovations! We understand
              the unique charm and potential of homes needing a little extra
              love.
            </Typography>
            <br />
            <Typography
              sx={{
                fontSize: "1.25rem",
                lineHeight: "2.25rem",
                color:
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.main,
              }}
            >
              Specializing in listing and selling properties requiring
              attention, CarpHomes offers a seamless blend of realty services
              and renovation expertise. Let us guide you through the process
              with ease, making your journey to a successful sale smooth and
              stress-free.
            </Typography>
          </Box>
          <Box mt={5}>
            <ReactPlayer url={Commercial} controls="true" width="100%" />
          </Box>
          <Box sx={{ marginTop: "2.5em" }}>
            <Button
              variant="outlined"
              color="warning"
              sx={{ padding: "15px 25px" }}
            >
              <Link className="link-item" to="/about">
                <Typography
                  sx={{
                    color:
                      props.theme.palette.mode === "dark"
                        ? props.theme.palette.common.white
                        : props.theme.palette.primary.main,
                  }}
                >
                  About Us
                </Typography>
              </Link>
            </Button>
          </Box>
        </section>
      </div>

      <section
        className="gallery"
        style={{
          backgroundColor:
            props.theme.palette.mode === "dark"
              ? props.theme.palette.primary.dark
              : props.theme.palette.primary.main,
        }}
      >
        <Box padding={5}>
          <Typography
            textAlign={"center"}
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.common.white
            }
            fontSize="2rem"
            mt={10}
            mb={5}
          >
            Explore stunning transformations that attest to our expertise
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
        >
          <ImageList sx={{ width: 1000, height: 670, marginBottom: '7.5rem' }} cols={2} rowHeight={400}>
            {itemData.map((item) => (
              <ImageListItem key={item.img}>
                <img
                  srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                  alt={item.title}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      </section>

      <div className="content-wrapper">
        <section className="sell-my-home">
          <Box
            className="smh-wrapper"
            mt={5}
            sx={{
              textAlign: "center",
              // padding: "5em",
            }}
          >
            <Box>
              <Typography
                sx={{
                  color:
                    props.theme.palette.mode === "dark"
                      ? props.theme.palette.secondary.light
                      : props.theme.palette.primary.main,
                  fontSize: "2rem",
                  fontFamily: "karla",
                }}
              >
                Ready to sell your home stress-free? Let us guide you through
                the process with ease.
              </Typography>
            </Box>
            <Box sx={{ marginTop: "2.5em" }}>
              <Button
                variant="contained"
                color="warning"
                sx={{ padding: "15px 25px" }}
              >
                <Link className="link-item" to="/sell-my-home">
                  <Typography
                    sx={{
                      color:
                        props.theme.palette.mode === "dark"
                          ? props.theme.palette.common.white
                          : props.theme.palette.common.white,
                      fontFamily: "latoBold",
                      fontSize: "1.15rem",
                    }}
                  >
                    Sell My Home
                  </Typography>
                </Link>
              </Button>
            </Box>
          </Box>

          <Box
            bgcolor={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.primary.light
                : props.theme.palette.secondary.main
            }
            sx={{
              borderRadius: "25px 25px 0 0",
            }}
          >
            <Box className="contact-us-text-container">
              <Typography
                variant="h4"
                fontFamily={"karla"}
                color={
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.secondary.dark
                    : props.theme.palette.primary.main
                }
              >
                Contact Us Today
              </Typography>
              <Typography
                lineHeight={2.5}
                mt={2}
                color={
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.dark
                }
              >
                Ready to embark on your selling journey with confidence? Reach
                out to us today to schedule a consultation and discover how
                CarpHomes can help you achieve your real estate goals.
              </Typography>
              {/* form */}
              <br />
              <Typography
                color={
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.dark
                }
                lineHeight={2.5}
              >
                A member of our team will promptly follow up to discuss the next
                steps in selling your home professionally and efficiently!
              </Typography>
              <Box display="flex" justifyContent="center" mt={5}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor:
                      props.theme.palette.mode === "dark"
                        ? props.theme.palette.warning.main
                        : props.theme.palette.primary.main,
                  }}
                >
                  <Link className="link-item" to="/contact">
                    <Typography color={props.theme.palette.common.white}>
                      Reach Out
                    </Typography>
                  </Link>
                </Button>
              </Box>
            </Box>
          </Box>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default Splash;
