import React from "react";
import "./About.scss";
import { Link } from "react-router-dom";

import Appbar from "../../components/Appbar/Appbar";
import Footer from "../../components/Footer/Footer";
import { Typography, Box, Button } from "@mui/material";
// import { ArrowBackIos } from '@mui/icons-material';
// import { Box } from '@mui/material';

import TravisHeadshot from "../../assets/Travis_headshot.png";
import JoyHeadshot from "../../assets/Joy_headshot.png";

const About = (props) => {
  return (
    <div>
      <Appbar colorMode={props.colorMode} theme={props.theme} />
      <Box
        className="header"
        bgcolor={
          props.theme.palette.mode === "dark"
            ? props.theme.palette.primary.dark
            : props.theme.palette.secondary.main
        }
      >
        <Typography
          variant="h2"
          sx={{
            color:
              props.theme.palette.mode === "dark"
                ? props.theme.palette.warning.main
                : props.theme.palette.primary.main,
          }}
        >
          About Us
        </Typography>
      </Box>

      <div className="content-wrapper">
        <Box padding={5} mt={5}>
          <Typography textAlign={"center"} lineHeight={2.5} fontSize={20}
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
            }
          >
            The heart of CarpHomes – our dedicated team of professionals
            committed to making your real estate journey exceptional. Get to
            know the individuals behind the expertise, passion, and personalized
            service that drive our success. Meet the faces who will guide you
            through every step of your listing journey, ensuring a seamless
            and rewarding experience from start to finish.
          </Typography>
        </Box>

        <div id="tm-1" className="team-member-container">
          <Box padding={2.5}>
            <Box
              bgcolor={
                props.theme.palette.mode === "dark"
                  ? props.theme.palette.secondary.main
                  : props.theme.palette.warning.main
              }
              height={"300px"}
              width={"300px"}
              borderRadius={50}
              overflow={"hidden"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"end"}
            >
              <img
                src={TravisHeadshot}
                height={"275px"}
                width={"auto"}
                style={{
                  marginLeft: "0.75em",
                }}
                alt="Travis Carpenter Headshot"
              />
            </Box>
            <Typography
              textAlign={"center"}
              fontFamily={"latoBold"}
              mt={2.5}
              sx={{
                color:
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.secondary.main
                    : props.theme.palette.warning.main,
              }}
            >
              Travis Carpenter, CEO
            </Typography>
          </Box>

          <Box padding={2.5}>
            <Typography lineHeight={2}
              color={
                props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
              }
            >
              I am an Indianapolis native, proud alumni of Center Grove High
              School, and a graduate of Indiana Wesleyan University in Marion,
              IN, where I earned my bachelor's degree. My journey in real estate
              began in 2018, and since then, I have garnered extensive
              experience in various facets of the industry. From selling homes
              both on and off-market to acquiring properties, managing
              renovation projects, and building rental portfolios, I have honed
              my skills in navigating complex legal transactions and providing
              valuable consulting services.
            </Typography>
            <br />
            <Typography lineHeight={2}
              color={
                props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
              }
            >
              As a dedicated professional, I prioritize exceptional customer
              service and practical problem-solving to ensure client
              satisfaction. My ability to make confident decisions, coupled with
              a steadfast commitment to professionalism, has earned me the trust
              of clients and colleagues alike.
            </Typography>
            <br />
            <Typography lineHeight={2}
              color={
                props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
              }
            >
              Outside of work, I am a fervent people-person who relishes the
              opportunity to forge meaningful connections. Alongside my wife,
              Deanna, I reside in the north suburbs, where we eagerly anticipate
              the arrival of our first child this summer. Our household is
              completed by our beloved dogs, Frodo and Lexi.
            </Typography>
          </Box>
        </div>

        <div id="tm-2" className="team-member-container">
          <Box padding={2.5}>
            <Typography lineHeight={2}
              color={
                props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
              }
            >
              I find that long drives have a calming effect, providing me with
              the space to think clearly and recharge. There's something about
              the breeze on my face that brings a sense of renewal. Similarly,
              spending time at the beach, whether with friends or in solitude,
              offers a peaceful respite from the hustle and bustle of everyday
              life. Watching the sunset is a particular favorite of mine, as it
              never fails to evoke a sense of wonder and appreciation for the
              beauty of nature.
            </Typography>
          </Box>
          <Box padding={2.5}>
            <Box
              bgcolor={
                props.theme.palette.mode === "dark"
                  ? props.theme.palette.secondary.main
                  : props.theme.palette.warning.main
              }
              height={"300px"}
              width={"300px"}
              borderRadius={50}
              overflow={"hidden"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"end"}
            >
              <img
                src={JoyHeadshot}
                height={"275px"}
                width={"auto"}
                style={{
                  marginLeft: "-1em",
                }}
                alt="Joy Navarro Headshot"
              />
            </Box>

            <Typography
              textAlign={"center"}
              fontFamily={"latoBold"}
              mt={2.5}
              sx={{
                color:
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.secondary.main
                    : props.theme.palette.warning.main,
              }}
            >
              Joy Navarro, Executive Assistant
            </Typography>
          </Box>
        </div>
        <Box
          bgcolor={
            props.theme.palette.mode === "dark"
              ? props.theme.palette.primary.light
              : props.theme.palette.secondary.main
          }
          sx={{
            borderRadius: '25px 25px 0 0',
          }}
        >
          <Box className="contact-us-text-container">
            <Typography variant="h4" fontFamily={"karla"}
              color={
                props.theme.palette.mode === "dark"
                ? props.theme.palette.secondary.dark
                : props.theme.palette.primary.main
              }
            >
              Contact Us Today
            </Typography>
            <Typography lineHeight={2.5} mt={2}
              color={
                props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
              }
            >
              Ready to embark on your selling journey with confidence? Reach out
              to us today to schedule a consultation and discover how CarpHomes
              can help you achieve your real estate goals.
            </Typography>
            {/* form */}
            <br />
            <Typography
              color={
                props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
              }
              lineHeight={2.5}
            >
              A member of our team will promptly follow up to discuss the next
              steps in selling your home professionally and efficiently!
            </Typography>
            <Box display="flex" justifyContent="center" mt={5}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor:
                      props.theme.palette.mode === "dark"
                        ? props.theme.palette.warning.main
                        : props.theme.palette.primary.main,
                  }}
                >
                  <Link className="link-item" to="/contact">
                    <Typography color={props.theme.palette.common.white}>
                      Reach Out
                    </Typography>
                  </Link>
                </Button>
              </Box>
          </Box>
          {/* Joy@Carphomes.net
              317-812-1388 */}
        </Box>
      </div>

      <Box>
        <Footer />
      </Box>
    </div>
  );
};

export default About;
