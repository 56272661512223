import React from 'react';
import './AvailableHomes.scss';

import Appbar from '../../components/Appbar/Appbar';
import { Box, Typography } from '@mui/material';

import HouseLogoPlain_light from '../../assets/carp_house_plain.png';
import HouseLogoPlain_dark from '../../assets/carp-house-plain-dark.png';

const AvaiableHomes = (props) => {
  return (
    <div>
      <Appbar colorMode={props.colorMode} theme={props.theme} />
      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' sx={{ height: 'calc(100vh - 64px)', }}>
        <Box mb={5}>
          {
            props.theme.palette.mode === "dark" ? (
              <img src={HouseLogoPlain_light} height='150px' width='auto' alt='CarpHomes House Logo light' />
            ) : (
              <img src={HouseLogoPlain_dark} height='150px' width='auto' alt='CarpHomes House Logo dark' />
            )
          }
        </Box>
        <Box textAlign='center'>
          <Typography sx={{ fontFamily: 'karla', fontSize: '1.5rem', color: props.theme.palette.mode === "dark" ? props.theme.palette.secondary.light : props.theme.palette.primary.dark }}>We currently do not have any available listings.</Typography>
          <br/>
          <Typography sx={{ color: props.theme.palette.mode === "dark" ? props.theme.palette.common.white : props.theme.palette.primary.main }}>Please check back again soon.</Typography>
        </Box>
      </Box>
    </div>
  )
}

export default AvaiableHomes;