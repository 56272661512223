import React from "react";
import "./Contact.scss";
import emailjs from "@emailjs/browser";

import Appbar from "../../components/Appbar/Appbar";
import Footer from "../../components/Footer/Footer";
import { Box, Typography, TextField, Button } from "@mui/material";

const Contact = (props) => {
  const form = React.useRef();

  const [to_name, setToName] = React.useState("");
  const [from_name, setFromName] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [phone, setPhone] = React.useState("");

  const sendEmail = (e) => {
    e.preventDefault();
    console.log(form.current);

    emailjs
      .sendForm(
        "service_6zy89mx",
        "template_v7goata",
        form.current,
        "9dDfLbFoZurbCagy1"
      )
      .then(
        (result) => {
          console.log(result.text);
          // let po_btn = document.getElementById("po_btn");
          // po_btn.click();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div>
      <Appbar colorMode={props.colorMode} theme={props.theme} />
      <Box
        className="header"
        bgcolor={
          props.theme.palette.mode === "dark"
            ? props.theme.palette.primary.dark
            : props.theme.palette.secondary.main
        }
      >
        <Typography
          variant="h2"
          sx={{
            color:
              props.theme.palette.mode === "dark"
                ? props.theme.palette.warning.main
                : props.theme.palette.primary.main,
          }}
        >
          Contact
        </Typography>
      </Box>
      <div className="content-wrapper">
        <Box padding={5} mt={5}>
          <Typography fontSize={'20px'}
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
            }
          >
            Ready to take the next step in selling your home? Have a question or
            inquiry about our services? Whatever your needs may be, we're here
            to help. Don't hesitate to reach out to us today. Your journey to a
            successful home sale starts here.
          </Typography>
        </Box>
        <Box padding={4}>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            ref={form}
            onSubmit={(event) => {
              sendEmail(event);
              setFromName("");
              setToName("");
              setMessage("");
              setPhone("");
            }}
          >
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignContent={"flex-start"}
            >
              <Box padding={1}>
                <TextField
                  id="to_name"
                  name="to_name"
                  label="full name"
                  variant="outlined"
                  value={to_name}
                  onChange={(event) => setToName(event.target.value)}
                />
              </Box>
              <Box padding={1}>
                <TextField
                  id="from_name"
                  name="from_name"
                  label="email address"
                  variant="outlined"
                  value={from_name}
                  onChange={(event) => setFromName(event.target.value)}
                />
              </Box>
              <Box padding={1}>
                <TextField
                  id="phone"
                  name="phone"
                  label="phone number"
                  variant="outlined"
                  value={phone}
                  onChange={(event) => setPhone(event.target.value)}
                />
              </Box>
            </Box>
            <br/>
            <Box padding={1}>
              <TextField
                fullWidth
                multiline
                id="message"
                name="message"
                label="your inquiry"
                variant="outlined"
                value={message}
                onChange={(event) => setMessage(event.target.value)}
              />
            </Box>
            <br/>
            <Box ml={1}>
              <Button type="submit" variant="contained" color="warning">Submit</Button>
            </Box>
          </Box>
        </Box>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
