import React from "react";
import "./App.scss";
import { Routes, Route } from "react-router-dom";

import Splash from "./pages/Splash/Splash";
import SellMyHome from "./pages/SellMyHome/SellMyHome";
import About from "./pages/About/About";
import AvailableHomes from "./pages/AvailableHomes/AvailableHomes";
import FAQs from "./pages/FAQs/FAQs";
import Contact from "./pages/Contact/Contact";
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';

import { ThemeProvider, useTheme, createTheme, Box } from "@mui/material";
const ColorModeContext = React.createContext({
  toggleColorMode: () => {},
});

function App(props) {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);

  return (
    <div className="App">
      <Box
        sx={{
          bgcolor: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.background.default,
        }}
      >
        <Routes>
          <Route
            index
            element={<Splash colorMode={colorMode} theme={theme} />}
          />
          <Route
            path="sell-my-home"
            element={<SellMyHome colorMode={colorMode} theme={theme} />}
          />
          <Route 
            path="about" 
            element={<About colorMode={colorMode} theme={theme} />} 
          />
          <Route 
            path="available-homes" 
            element={<AvailableHomes colorMode={colorMode} theme={theme} />} 
          />
          <Route 
            path="faq" 
            element={<FAQs colorMode={colorMode} theme={theme} />} 
          />
          <Route 
            path="contact" 
            element={<Contact colorMode={colorMode} theme={theme} />} 
          />
          <Route 
            path="privacy-policy"
            element={<PrivacyPolicy colorMode={colorMode} theme={theme} />}
          />
        </Routes>
      </Box>
    </div>
  );
}

export default function ToggleColorMode() {
  const [mode, setMode] = React.useState("light");
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          common: {
            black: "#000",
            white: "#fff",
          },
          primary: {
            main: "#1a2038",
            light: '#202845',
            dark: '#14192b',
            // contrastText: '',
            ...(mode === "dark" && {
              main: "#1a2038",
              // light: '',
              // dark: '',
              // contrastText: '',
            }),
          },
          secondary: {
            main: "#abc3b5",
            light: '#b8d1c2',
            dark: '#a0b6a9',
            // contrastText: '',
          },
          error: {
            main: "#1a2038",
            // light: '',
            // dark: '',
            // contrastText: '',
          },
          warning: {
            main: "#c76119",
            // light: '',
            // dark: '',
            // contrastText: '',
          },
          info: {
            main: '#1a3553',
            light: '#1e3d60',
            dark: '#162d46',
            // contrastText: '',
          },
          success: {
            main: '#d8b57b',
            light: '#e5c083',
            dark: '#c9a973',
            // contrastText: '',
          },
          background: {
            paper: '#fff',
            default: '#fff'
          },
          action: {
            active: 'rgba(0, 0, 0, 0.54)',
            hover: 'rgba(0, 0, 0, 0.04)',
            hoverOpacity: '0.04',
            selected: 'rgba(0, 0, 0, 0.08)',
            selectedOpacity: '0.08',
            disabled: 'rgba(0, 0, 0, 0.26)',
            disabledBackground: 'rgba(0, 0, 0, 0.12)',
            disabledOpacity: '0.38',
            focus: 'rgba(0, 0, 0, 0.12)',
            focusOpacity: '0.12',
            activatedOpacity: '0.12',
          }
        },
        typography: {
          htmlFontSize: 16,
          fontFamily: 'latoRegular',
          fontSize: 16
        },
      }),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <App theme={theme} />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
