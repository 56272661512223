import React from "react";
import "./SellMyHome.scss";
import { Link } from "react-router-dom";

import Appbar from "../../components/Appbar/Appbar";
import Footer from "../../components/Footer/Footer";

import { Box, Typography, Button } from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

import Listing1 from "../../assets/listing1.jpg";

const SellMyHome = (props) => {
  return (
    <Box>
      <Appbar colorMode={props.colorMode} theme={props.theme} />
      <div className="fixed-nav-offset">{/* sell my home */}</div>
      <div className="content-wrapper">
        <Box mt={2.5}>
          <Box
            className="smh-heading-container"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box flex={1} m={2.5}>
              <Typography
                variant="h4"
                fontFamily={"karla"}
                mb={2.5}
                color={
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.warning.main
                    : props.theme.palette.warning.main
                }
              >
                Your Premier Fixer-Upper Listing Agent
              </Typography>
              <Typography
                lineHeight={2.5}
                color={
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.dark
                }
              >
                Are you feeling overwhelmed by the prospect of selling your
                fixer-upper home? Have you encountered real estate agents who
                presented you with extensive lists of repairs and renovations
                deemed necessary for a successful sale? Rest assured, CarpHomes
                is here to offer you a different approach. We specialize in
                listing and selling homes that require a little extra care and
                attention. With our distinctive blend of real estate expertise
                and renovation knowledge, we're committed to ensuring a smooth
                and stress-free selling experience for you.
              </Typography>
            </Box>
            <Box flex={1} m={2.5}>
              <img
                src={Listing1}
                height="auto"
                width="100%"
                style={{
                  borderRadius: "25px",
                  boxShadow: "2.5px 2.5px 5px #333",
                  maxHeight: "500px",
                  maxWidth: "752px",
                }}
                alt="Living room of a flipped home"
              />
            </Box>
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={3}
          >
            <Box>
              <Box
                sx={{ width: "87.5%", margin: "0 auto", textAlign: "center" }}
              >
                <Typography
                  variant="h4"
                  fontFamily={"karla"}
                  mb={2.5}
                  mt={5}
                  color={
                    props.theme.palette.mode === "dark"
                      ? props.theme.palette.secondary.light
                      : props.theme.palette.primary.dark
                  }
                >
                  Why Choose Us?
                </Typography>
                <Typography
                  lineHeight={2.5}
                  color={
                    props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.dark
                  }
                >
                  Unlike traditional real estate agents, we possess a unique
                  skill set that sets us apart. Our founder, Travis Carpenter,
                  brings a wealth of expertise derived not only from the real
                  estate industry but also from his hands-on experience in home
                  renovation. With a deep understanding of the intricacies
                  involved in selling a fixer-upper property, we go beyond
                  merely listing your home. We meticulously assess its potential
                  and value from a renovator's perspective, enabling us to
                  accurately price your property and maximize its market appeal.
                </Typography>
              </Box>
              <Box padding={2} mt={5} borderRadius={25}>
                <Typography
                  variant="h4"
                  textAlign="center"
                  fontFamily={"karla"}
                  fontSize={36}
                  mb={2.5}
                  mt={5}
                  color={
                    props.theme.palette.mode === "dark"
                      ? props.theme.palette.warning.main
                      : props.theme.palette.warning.main
                  }
                >
                  Our Operations
                </Typography>
                <Timeline position="alternate-reverse">
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography
                        variant="h6"
                        color={
                          props.theme.palette.mode === "dark"
                            ? props.theme.palette.secondary.light
                            : props.theme.palette.primary.dark
                        }
                      >
                        Comprehensive Home Evaluation
                      </Typography>
                      <Typography
                        lineHeight={2}
                        color={
                          props.theme.palette.mode === "dark"
                            ? props.theme.palette.common.white
                            : props.theme.palette.primary.dark
                        }
                      >
                        Our team will walk through your home with you to identify any
                        underlying issues or areas requiring improvement,
                        providing you with invaluable insights to make informed
                        decisions.
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography
                        variant="h6"
                        color={
                          props.theme.palette.mode === "dark"
                            ? props.theme.palette.secondary.light
                            : props.theme.palette.primary.dark
                        }
                      >
                        Accurate Pricing
                      </Typography>
                      <Typography
                        lineHeight={2}
                        color={
                          props.theme.palette.mode === "dark"
                            ? props.theme.palette.common.white
                            : props.theme.palette.primary.dark
                        }
                      >
                        Leveraging our dual expertise in real estate and
                        renovation, we determine optimal listing prices tailored
                        to your property's unique characteristics and potential
                        enhancements.
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography
                        variant="h6"
                        color={
                          props.theme.palette.mode === "dark"
                            ? props.theme.palette.secondary.light
                            : props.theme.palette.primary.dark
                        }
                      >
                        Strategic Marketing
                      </Typography>
                      <Typography
                        lineHeight={2}
                        color={
                          props.theme.palette.mode === "dark"
                            ? props.theme.palette.common.white
                            : props.theme.palette.primary.dark
                        }
                      >
                        We understand that every seller has specific preferences
                        and priorities. That's why we tailor our approach to
                        accommodate your individual needs, ensuring a seamless
                        and personalized selling process.
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography
                        variant="h6"
                        color={
                          props.theme.palette.mode === "dark"
                            ? props.theme.palette.secondary.light
                            : props.theme.palette.primary.dark
                        }
                      >
                        Customized Approach
                      </Typography>
                      <Typography
                        lineHeight={2}
                        color={
                          props.theme.palette.mode === "dark"
                            ? props.theme.palette.common.white
                            : props.theme.palette.primary.dark
                        }
                      >
                        We develop targeted marketing strategies to effectively
                        showcase the distinctive features and possibilities of
                        your fixer-upper home, attracting the right buyers while
                        transparently disclosing any pertinent information.
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                </Timeline>
              </Box>
            </Box>
          </Box>

          <Box
            bgcolor={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.primary.light
                : props.theme.palette.secondary.main
            }
            sx={{
              borderRadius: "25px 25px 0 0",
            }}
          >
            <Box className="contact-us-text-container">
              <Typography
                variant="h4"
                fontFamily={"karla"}
                color={
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.secondary.dark
                    : props.theme.palette.primary.main
                }
              >
                Contact Us Today
              </Typography>
              <Typography
                lineHeight={2.5}
                mt={2}
                color={
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.dark
                }
              >
                Ready to embark on your selling journey with confidence? Reach
                out to us today to schedule a consultation and discover how
                CarpHomes can help you achieve your real estate goals.
              </Typography>
              {/* form */}
              <br />
              <Typography
                color={
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.dark
                }
                lineHeight={2.5}
              >
                A member of our team will promptly follow up to discuss the next
                steps in selling your home professionally and efficiently!
              </Typography>
              <Box display="flex" justifyContent="center" mt={5}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor:
                      props.theme.palette.mode === "dark"
                        ? props.theme.palette.warning.main
                        : props.theme.palette.primary.main,
                  }}
                >
                  <Link className="link-item" to="/contact">
                    <Typography color={props.theme.palette.common.white}>
                      Reach Out
                    </Typography>
                  </Link>
                </Button>
              </Box>
            </Box>
            {/* Joy@Carphomes.net
              317-812-1388 */}
          </Box>
        </Box>
      </div>
      <Footer />
    </Box>
  );
};

export default SellMyHome;
