import React from "react";
import "./Footer.scss";

import CarpHomesLogo from "../../assets/carp-homes-footer-logo.png";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

const Footer = (props) => {
  return (
    <div>
      <div className="footer-container">
        <div className="footer-flexContainer">
          <div className="image-container">
            {/* replace logo with logo using the correct orange */}
            <img className="footer-logo" src={CarpHomesLogo} alt="" />
          </div>
          <Typography className="logo-tagline" sx={{ fontSize: "1.25rem", marginLeft: "1.25em" }}>
            Simplifying Sales, Any Condition
          </Typography>
        </div>
        <div className="footer-flexContainer">
          <Typography variant="h5" sx={{ fontFamily: "karla" }}>
            Explore
          </Typography>
          <div className="underline"></div>
          <Link className="link-item" to="/">
            <Typography color="white">Home</Typography>
          </Link>
          <Link className="link-item" to="/sell-my-home">
            <Typography color="white">Sell My Home</Typography>
          </Link>
          <Link className="link-item" to="/about">
            <Typography color="white">About</Typography>
          </Link>
          <Link className="link-item" to="/available-homes">
            <Typography color="white">Available Homes</Typography>
          </Link>
          {/* <Link to='/'>
            <Typography>Home</Typography>
          </Link> */}
        </div>
        <div className="footer-flexContainer">
          <Typography variant="h5" sx={{ fontFamily: "karla" }}>
            Info & Contact
          </Typography>
          <div className="underline"></div>
          <Link className="link-item" to="/faq">
            <Typography color="white">FAQ's</Typography>
          </Link>
          <Link className="link-item" to="/contact">
            <Typography color="white">Contact</Typography>
          </Link>
          <Link className="link-item" to="/privacy-policy">
            <Typography color="white">Privacy Policy</Typography>
          </Link>
        </div>
        {/* <div className='footer-flexContainer'>
          
        </div> */}
      </div>
    </div>
  );
};

export default Footer;
